<template>
  <div class="col-xs-12">
    <base-confirm-modal
      title=""
      :subject="selected"
      :show="deleteItemsModalVisible"
      @close="deleteItemsModalVisible = false"
      @cancel="deleteItemsModalVisible = false"
      @confirm="startRemoveAttachments"
    >
      Czy na pewno chcesz usunąć?
    </base-confirm-modal>
    <div
    >
      <div>
        <div
          class="folder-imitation"
          :style="{

              }"
        >
          <p> Wszystkie </p>
          {{ ' ' }}
        </div>
        <div
          class="category-container-opened"
          :style="{
                background: '#dce6f7',
                minHeight: '65vh',
                maxHeight: '65vh',
                cursor: 'auto'
              }"
        >
          <template v-for="(date) in dates">
            <div :key="`separator-${date}`" class="attachments-date-separator">
              <div
                class="attachments-dates-item-select"
              >
                <input
                  :id="date"
                  type="checkbox"
                  name="selected"
                  :checked="selectedDates.includes(date)"
                  @click="() => {
                          toggleDateItem(date)
                        }"
                >
                <label :for="date" >{{date}}</label>
              </div>
            </div>
          <div
            class="attachments-preview-container" :key="date"
          >
            <div
              v-for="singleAttachment in filterAttachmentByDate(date)"
              :key="singleAttachment.id"
              @click.ctrl="() => {
                    lastSelectedAttachment = singleAttachment.id
                    toggleItem(singleAttachment.id)
                  }"
              @click.shift.exact="() => toggleItems(singleAttachment.id, filterAttachmentByDate(date))"
            >
              <div
                class="attachments--item"
                @mouseover="(e) => showTooltip(e, singleAttachment.id)"
                @mouseleave="() => hideTooltip(singleAttachment.id)"
              >
                <div
                  class="attachments--item-select"
                  @click="() => toggleItem(singleAttachment.id)"
                >
                  <input
                    :id="singleAttachment.id"
                    type="checkbox"
                    name="selected"
                    :checked="selected.includes(singleAttachment.id)"
                    @click="() => {
                          lastSelectedAttachment = singleAttachment.id
                          toggleItem(singleAttachment.id)
                        }"
                  >
                  <label :for="singleAttachment.id" />
                </div>
                <div class="attachment--item--container">
                  <img
                    :id="singleAttachment.id"
                    :src="singleAttachment.value"
                    @click.exact="(e) => $emit('preview', {e, singleAttachment})"
                  >
                  <p
                    :title="singleAttachment.filename"
                  >
                    {{ fileName(singleAttachment) }}
                  </p>
                </div>
                <transition
                  name="slide-right-centered"
                  mode="in-out"
                >
                  <FileTooltip
                    v-if="mouseOverAttachment === singleAttachment.id"
                    :class="tooltipClass"
                    :model="attachments.hasOwnProperty(mouseOverAttachment) ? attachments[mouseOverAttachment] : {} "
                    :categories="[]"
                  />
                </transition>
              </div>
            </div>
          </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileTooltip from '../../../../../share/tooltip/Tooltip'
import tooltipHandling from '../mixins/tooltipHandling'
import selectingAttachments from '../mixins/selectingAttachments'
import moment from 'moment'
import removingAttachments from '../mixins/removingAttachments'
import BaseConfirmModal from '../../../../../share/modal/BaseConfirmModal'

export default {
    name: 'ViewOnlyContainer',
    components: { BaseConfirmModal, FileTooltip },
    mixins: [
      tooltipHandling,
      selectingAttachments,
      removingAttachments
    ],
    props: {
      attachments: {type: Object, default: () => {}},
      taskType: { type: String, default () { return 'main' } },
      service: { type: String, default () { return '' } },
    },
    data() {
      return {
      events: {
        downloadSelected: `${this.service}:${this.taskType}:downloadSelectedAttachmentsViewMode`,
        removeSelected: `${this.service}:${this.taskType}:removeSelectedAttachmentsViewMode`
        },
        selectedDates: []
      }
    },
    computed: {
      viewOnlyAttachments (date) {
          const sorting = {
          alphaNum: (a, b) => a.filename.localeCompare(b.filename, 'pl', { numeric: true }),
          createdAndFilename: (a, b) => (a.createdAt.substring(0,13) + a.filename).localeCompare(b.createdAt.substring(0,13) + b.filename, 'pl', { numeric: true }),
          date: (a, b) => moment(a.createdAt).valueOf() > moment(b.createdAt).valueOf() ? 1 : -1
        }
        
        return Object.entries(this.attachments).
          map(([key, value], index) => {
            value.uuid = key
            return value
          }).sort(sorting.createdAndFilename)
      },
      dates () {
        moment.defaultFormat = 'DD-MM-YYYY'
        return [...new Set(this.viewOnlyAttachments.map(att => this.formatDate(att.createdAt)))].sort((a, b) => moment(a, moment.defaultFormat).valueOf() - moment(b, moment.defaultFormat).valueOf()).reverse()
      }
    },
    mounted () {
      this.$events.on(this.events.downloadSelected, this.downloadSelected)
      this.$events.on(this.events.removeSelected, this.deleteFiles)
    },
    methods: {
      fileName (file) {
        if (file.filename.length > 20) {
          return file.filename.slice(0, 20) + '...'
        } else {
          return file.filename
        }
      },
      filterAttachmentByDate (date) {
        return this.viewOnlyAttachments.filter(att => this.formatDate(att.createdAt) === date)
      },
      formatDate (date) {
        return moment(date).format('DD-MM-YYYY')
      },
      toggleDateItem(date) {
        if (this.selectedDates.includes(date)) {
          this.selectedDates.splice(this.selectedDates.indexOf(date), 1)
          this.filterAttachmentByDate(date).forEach(att => this.toggleItem(att.id))
        } else {
          this.selectedDates.push(date)
          this.filterAttachmentByDate(date).forEach(att => this.toggleItem(att.id))
        }
      }
    }
  }
</script>

<style scoped>
  .attachments-date-separator:not(:first-child) {
    border-top: #5bc0de solid 2px;
  }
  .attachments-date-separator {
    margin: 10px 0 10px 0;
    position: relative;
    width: 100%;
  }
  #attachments-container .attachments-date-separator label {
    margin: 6px;
    font-size: 18px;
  }

</style>
