export default {
  data () {
    return {
      events: {},
    }
  },
  methods: {
    $getEvents () {
      this.events = {
        uploadFiles: `${this.service}:${this.taskType}:uploadAttachments`,
        rotateFiles: `${this.service}:${this.taskType}:rotateAttachments`,
        deleteFiles: `${this.service}:${this.taskType}:removeSelectedAttachments`,
        download: `${this.service}:${this.taskType}:downloadAttachments`,
        downloadSelected: `${this.service}:${this.taskType}:downloadSelectedAttachments`,
        sendToAudanet: `${this.service}:${this.taskType}:sendAttachmentToAudanet`,
        invertSelection: `task:attachments:invertSelection`,
        toggleView: 'toggleAttachmentsView'
      }
    },
    $listenToEvents () {
      this.$events.on(this.events.uploadFiles, this.addFilesToForm)
      this.$events.on(this.events.deleteFiles, this.deleteFiles)
      this.$events.on(this.events.download, this.download)
      this.$events.on(this.events.downloadSelected, this.downloadSelected)
      this.$events.on(this.events.sendToAudanet, this.initSendToAudanet)
      this.$events.on(this.events.invertSelection, this.invertSelection)
      this.$events.on(this.events.toggleView, this.toggleAttachmentsView)
      this.$events.on(this.events.rotateFiles, this.rotateAttachments)
    },
  }
}
