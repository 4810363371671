<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"

    @show="open"
    @close="$emit('close')"
    @beforeSubmit="function (bool) {beforeSubmitTrigger = bool}"
  >
      <form-modal
      :is-before-submit="beforeSubmitTrigger"
      :service="service"
      v-model="model"
      :show="show"
      ref="form"
      @invoiceClearCorrection="handleInvoiceClearCorrection"
      :task-type="taskType"
    >
    </form-modal>
  </base-form-modal>
</template>

<script>
import FormModalMixin from '@/components/share/modal/FormModalMixin'
import FormModal from '../form/Invoice'
import api from '@/api'
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin,
    ErrorNotify
  ],
  props: {
    service: {type: String, default: ''},
    taskNumber: {},
    taskId: {},
    taskType: {type: String, required: true}
  },
  data () {
    return {
      title: 'Dodaj fakturę',
      actionType: 1, // default: nowy wpis
      sending: false
    }
  },
  methods: {
    submitForm () {
      if (this.sending === false) {
        this.sending = true
        let url = this.$isWithClients(this.service) ? '/invoices' : `/tasks/invoices`
        api.request(this.service, 'post', url, this.createDataToSend())
          .then((response) => {
            this.$notify({
              type: 'success',
              text: 'Dodano fakturę'
            })
            this.$emit('success')
            this.$emit('close')
            this.$events.$emit('loadInvoice')
          })
          .catch((e) => {
            this.errorNotify(e)
          })
      }
    },
    createDataToSend () {
      let data = {
        number: this.taskNumber,
        amount: this.model.amount,
        invoiceNumber: this.model.invoiceNumber,
        date: this.model.date || '',
        description: this.model.description,
        action: this.actionType,
        correction: this.model.correction,
        originalInvoiceNumber: this.model.originalInvoiceNumber
      }

      if (this.$isWithClients(this.service)) {
        data['taskId'] = this.taskId
      }
      return data
    },
    handleInvoiceClearCorrection () {
      this.model.originalInvoiceNumber = null
    }
  }
}
</script>
