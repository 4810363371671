export default {
  data () {
    return {
      properties: {
        'voivodeship': 'Województwo',
        'county': 'Powiat',
        'municipalityType': 'Gmina',
        'postcode': 'Kod pocztowy',
        'place': 'Miejscowość',
        'district': 'Dzielnica',
        'street': 'Ulica',
        'buildingNumber': 'Numer budynku',
        'flatNumber': 'Numer mieszkania/lokalu'
      }
    }
  },
  methods: {
    filterAddressFields(fields) {
      let filteredFields = {}
      for (let field in fields) {
        this.properties.hasOwnProperty(field) ? filteredFields[this.properties[field]] = fields[field] : null
      }
      return filteredFields
    }
  }
}
