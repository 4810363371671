export default {
  data () {
    return {
      securityActions: []
    }
  },
  computed: {
    isAllowedToEdit () {
      if (this.$store.state.base.user.roles.includes('ROLE_ADMIN')) {
        return true
      }
      if (this.$store.state.base.user.roles.includes('ROLE_SUPER_ADMIN')) {
        return true
      }

      if (this.taskType === 'appraisal') {
        if (this.taskState.name === 'zrealizowane') {
          return this.blockAppraiser()
        } else {
          return this.blockFinal()
        }
      } else {
        return this.blockAppraiser() && this.blockFinal()
      }
    }
  },
  mounted () {
  },
  methods: {
    blockAppraiser () {
      return !(this.$store.state.base.user.roles.includes(
        'ROLE_RZECZOZNAWCA_BLS') ||
        this.$store.state.base.user.roles.includes(
          'ROLE_RZECZOZNAWCA_ZEWN'))
    },
    blockFinal () {
      return true
    },
    containsHiddenCategoryAction (categoryName) {
      switch (parseInt(categoryName)) {
        case 1:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_category_provided_by_bank'})
        case 2:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_category_payment_confirmation'})
        case 3:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_category_scanned_docs_for_bank'})
        case 4:
          return this.$auth.isAllowed({service: this.service, action: 'frontend_acl_get_category_documents'})
        default:
          return true
      }
    }
  }
}
