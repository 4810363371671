<template>
  <div>
    <div v-if="data" class="credit-purpose-data-container col-xs-12" >
      <div v-if="Array.isArray(data) && data !== null" >
        <label>{{label}}</label>
        <ul class="list-group" v-if="data.length !== 0">
          <li
            class="list-group-item list-group-item-overflowed"
            :key="`summary-field-${index}`"
            v-for="(element, index) in data"
          >
            {{ element }}
          </li>
        </ul>
        <ul class="list-group" v-else>
          <li
            class="list-group-item list-group-item-overflowed"
          >
            Brak
          </li>
        </ul>
      </div>
      <div v-else>
        <label>{{ label }}</label>
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-overflowed"
          >
            {{ data }}
          </li>
        </ul>
      </div>
    </div>
    <div class="credit-purpose-data-container col-xs-12" v-else>
      <label>{{ label }}</label>
      <ul class="list-group" >
        <li
          class="list-group-item list-group-item-overflowed"
        >
          Brak
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SummaryField',
  props: {
    data: {
      type: [Object, Array, String, Number]
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
