<template>
  <div
  >
    <base-form-modal
      id="change-state-modal"
      :show="show"
      :title="title"
      :ok-text="okText"
      :cancel-text="cancelText"
      :remember-model-after-close="true"
      @show="open"
      @close="handleCloseChangeStateModal"
      @beforeSubmit="bool => beforeSubmitTrigger = bool"
      :class="{'change-state-modal-invisible': simpleTransition}"
    >
      <form-modal
        ref="form"
        v-model="model"
        :is-before-submit="beforeSubmitTrigger"
        :task-id="taskId"
        :service="service"
        @changeTransition="handleChangeTransition"
        @multiselectInput="handleChangeUser"
        :client="client"
        :workflow-id="workflowId"
        :transitions="transitions"
        :transitions-loaded="transitionsLoaded"
        :possible-users="possibleUsers"
        :show-user-select="showUserSelect"
        :selected-transition-events="selectedTransitionEvents"
        :file-path="filePath"
        @filesLoaded="handleFilesLoaded"
        @deleteUploadedFile="handleDeleteUploadedFile"
        :files="m$files"
      />
    </base-form-modal>
    <blocking-loader :show="loading">
      {{ loaderMessage }}
    </blocking-loader>
    <blocking-loader :show="m$loadingAttachments" :progress="Math.floor((m$aggregator/m$allFiles) * 100)"
                     type="download">
      Trwa ładowanie plików. Proszę czekać.
    </blocking-loader>
  </div>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
import BlockingLoader from '../../../../share/BlockingLoader.vue'
import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
import FormModal from './form/ChangeState.vue'
import api from '../../../../../api'
import ErrorNotify from '../../../../share/mixins/ErrorNotify.js'
import {mapActions} from 'vuex'
import WebStorage from '../../../../share/WebStorage.js'
import fileMixin from './mixins/fileMixin.js'

export default {
  components: {
    BlockingLoader,
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ErrorNotify,
    fileMixin
  ],
  props: {
    isFormValid: {type: Boolean, default: () => true},
    changeStateEvent: {type: String, required: true},
    taskId: {required: true},
    taskState: {required: true, default: ''},
    workflowId: {type: Number || String, required: true},
    client: {required: true},
    service: {type: String, required: true},
    categoryGroup: {type: String, required: true},
    filePath: {type: String}
  },
  data () {
    return {
      simpleTransition: false,
      isMessageFieldVisible: false,
      isComment: false,
      isList: false,
      isCommentModalVisible: false,
      assigneesLoadedEvent: 'changeState:assignees:loaded',
      assigneesNotLoadedEvent: 'changeState:assignees:notLoaded',
      title: 'Przejdź dalej',
      okText: 'Zmień status',
      loading: false,
      loaderMessage: 'Trwa zapisywanie. Proszę czekać.',
      saveAllowed: false,
      transitionId: null,
      showUserSelect: false,
      transitions: [],
      transitionsLoaded: false,
      possibleUsers: [],
      model: {
        add_message: {
          type: 'order_message',
          order_message: true,
          isAnswer: true
        },
        reject_order: {
          type: 'public',
          public: true,
          isAnswer: false
        },
        cancel_order: {
          type: 'public',
          public: true,
          isAnswer: false
        },
        local_vision_appointed: {
          type: 'public',
          public: true,
          isAnswer: false
        },
        no_contact: {
          type: 'public',
          public: true,
          isAnswer: false
        }
      }
    }
  },
  watch: {
    taskState (newVal) {
      this.$refs.form.taskState = newVal
    },
    show (newVal) {
      if (newVal) {
        if (this.transitions.length === 0) {
          return
        }
        if (this.possibleUsers.length === 0) {
          return
        }
        if (this.transitions.length > 1) {
          return
        }
        if (this.possibleUsers.length > 1) {
          return
        }
        if (this.selectedTransitionEvents.length !== 0 && this.selectedTransitionEvents.some(event => {
          return this.model.hasOwnProperty(event)
        })) {
          return
        }
        this.loading = true
        this.simpleTransition = true
        this.submitForm()
      } else {
        this.$validator.reset()
      }
    }
  },
  computed: {
    selectedTransitionEvents () {
      return this.transitions.length !== 0
      && this.model.hasOwnProperty('selectedTransitionName')
      && this.model['selectedTransitionName'] ?
        this.transitions.find(el => parseInt(el.value) === parseInt(this.model['selectedTransitionName'])).events.map(event => event.name)
        : []
    }
  },
  mounted () {
    this.$refs.form.taskState = this.taskState
    this.$events.on(this.assigneesLoadedEvent, this.allowSave)
    this.$events.on(this.assigneesNotLoadedEvent, this.preventSave)
    this.loadStates()
  },
  methods: {
    ...mapActions({
      getIncompleteTasks: 'getIncompleteTasks'
    }),
    handleChangeTransition (event) {
      this.model.selectedTransitionName = event
      this.loadRolesWithUsers()
    },
    handleCloseChangeStateModal () {
      this.transitionId = null
      this.$emit('close')
    },
    allowSave () {
      this.saveAllowed = true
    },
    preventSave () {
      this.saveAllowed = false
    },
    callIncompleteTasksMethod () {
      let contractors = {}
      Object.entries(WebStorage.getSecurityActions()).filter(([contractorKey, contractorValue], index) => contractorValue.length !== 0 && (contractorKey === 'intgen' || contractorKey === 'intbls')).forEach(([contractorKey, contractorValue], index) => contractors[contractorKey] = contractorValue)
      this.getIncompleteTasks({services: contractors})
    },
    handleChangeUser (event) {
      this.model.selectedUserUuid = event
    },
    getNextStateMethod () {
      return api.request(this.service, 'put', `/tasks/${this.taskId}`, this.createDataToSend())
    },
    nextState () {
      if (!this.isDataValid()) {
        this.loading = false
        return
      }

      this.getNextStateMethod()
        .then((response) => {
          if (response.data.hasOwnProperty('message') && response.data.message.includes('error:')) {
            this.$notify({
              type: 'info',
              text: response.data.message.split('error:')[1]
            })
            this.loading = false
            return
          }

          this.$emit('close')
          this.$events.emit('task:changedState')
          this.callIncompleteTasksMethod()
          if (this.simpleTransition) {
            this.simpleTransition = false
          }
          this.loading = false
          this.$notify({
            type: 'success',
            text: 'Zmieniono status'
          })
        })
        .catch((error) => {
          this.$emit('close')
          this.loading = false
          if (this.simpleTransition) {
            this.simpleTransition = false
          }
          this.errorNotify(error, 'error', error.message, '', -1)
        })
    },
    submitForm () {
      if (!this.isFormValid) {
        return
      }
      if (this.saveAllowed) {
        this.loading = true
        this.nextState()
      }
    },
    createDataToSend () {
      let args = {}
      let data = {
        transitionId: this.model.selectedTransitionName,
        userUuid: this.model.selectedUserUuid ? this.model.selectedUserUuid : null
      }

      if (this.selectedTransitionEvents.length !== 0) {
        let eventsData = {}
        this.selectedTransitionEvents.forEach(event => {
          if (event === 'cancel_order') {
            let tempCancelEventData = {...this.model[event]}
            tempCancelEventData.hasOwnProperty('reason') ? tempCancelEventData.reason = tempCancelEventData.reason.map(e => e.value) : tempCancelEventData.reason = []
            eventsData[event] = tempCancelEventData
            return
          }
          if (event === 'add_message') {
            let tempOrderMessageEventData = {...this.model[event]}
            tempOrderMessageEventData['files'] = this.m$files.map(file => ({
              name: file.name,
              data: file.fileContent
            }))
            eventsData[event] = tempOrderMessageEventData
            return
          }
          eventsData[event] = this.model[event]
        })
        args['events_data'] = eventsData
      }
      if (args) {
        data = {
          ...data,
          ...args
        }
      }

      if (this.$isWithClients(this.service)) {
        data['nextState'] = true
        data['client'] = this.client
        data[this.categoryGroup] = true
      }
      return data
    },
    isDataValid () {
      this.vErrors.items.forEach(err => {
        this.$notify({
          type: 'error',
          title: '',
          text: err.msg
        })
      })

      let errorFields = this.vErrors.items.map(el => {
        if (el.field === 'reason' || el.field === 'reasons-message') {
          return 'cancel_order'
        }
        return el.field
      })

      if (this.selectedTransitionEvents.some(event => errorFields.includes(event))) {
        return false
      }

      if ((this.selectedTransitionEvents.includes('local_vision_appointed') && this.model['local_vision_appointed'] && !this.model['local_vision_appointed']['content']) || (this.selectedTransitionEvents.includes('local_vision_appointed') && !this.model['local_vision_appointed'])) {
        this.$notify({
          type: 'error',
          title: '',
          text: 'Data wizji lokalnej jest wymagana'
        })
        this.loading = false

        return false
      }
      if (this.vErrors.items.length > 0) {
        return false
      }

      return true
    },
    toggleLoader () {
      this.loading = !this.loading
    },
    getLoadRolesWithUsersMethod () {

      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/workflows/${this.workflowId}/transitions/${this.model['selectedTransitionName']}/${this.taskId}/tos-assignee-list?clientId=${this.client}`)
        : api.request(this.service, 'get', `/workflows/${this.workflowId}/transitions/${this.model['selectedTransitionName']}/${this.taskId}/tos-assignee-list`)
    },
    loadRolesWithUsers () {
      this.$events.emit('changeState:assignees:notLoaded')
      this.getLoadRolesWithUsersMethod()
        .then((response) => {
          this.toggleLoading()
          const result = response.data
          if (result.hidden) {
            this.showUserSelect = false
          } else {
            this.showUserSelect = true
            const usersUuids = Object.keys(result)
            this.possibleUsers = []

            for (let c = 0; c < usersUuids.length; c++) {
              let uuid = usersUuids[c]
              this.possibleUsers.push({
                label: result[uuid], // userData.forename + ' ' + user.userData.surname + ' [' + userRoles.join(', ') + ']',
                value: uuid
              })
            }
          }
          if (this.transitions.length === 1 && this.possibleUsers.length < 2) {
            this.model['selectedUserUuid'] = this.possibleUsers.length !== 0 ? this.possibleUsers[0].value : null
            // this.submitForm()
          } else if (this.transitions.length !== 1 && this.possibleUsers.length < 2) {
            this.model['selectedUserUuid'] = this.possibleUsers.length === 1 ? this.possibleUsers[0].value : null
          }
          this.$events.emit('changeState:assignees:loaded')
        })
        .catch(() => {
          this.toggleLoading()
          this.possibleUsers = []
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować użytkowników'
          })
        })
    },
    getTransitions () {
      return api.request(this.service, 'get', `tasks/${this.taskId}/transitions`)
    },
    loadStates () {
      this.transitionsLoaded = false
      this.getTransitions()
        .then((response) => {
          let buttonLabel = ''
          const transitions = response.data
          this.transitions = transitions.map(transition => {
            return {
              label: transition.label,
              value: transition.id,
              name: transition.name,
              events: transition.transition_events
            }
          })

          if (this.transitions.length === 1) {
            buttonLabel = this.transitions[0].label
            this.handleChangeTransition(this.transitions[0].value)
          }

          this.setButtonLabel(buttonLabel)
          this.transitionsLoaded = true
        })
        .catch((e) => {
          console.error(e)
          // TODO: distinguish between error and last possible transition
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    },
    setButtonLabel (label) {
      let buttonLabel = (label !== '') ? label : 'Przejdź dalej'
      this.$events.$emit('dashboard:submenu:stateLabel', buttonLabel)
    }
  }
}
</script>
<style>
#change-state-modal.change-state-modal-invisible {
  opacity: 0;
}
</style>
