<template>
  <form id="invoiceForm">
    <money-type
      v-model="value['amount']"
      v-validate="{ rules: { money: 'money' }}"
      :error-message="vErrors.first('amount')"
      :required="true"
      label="Kwota netto"
      :name="'amount'"
      data-vv-value-path="value"
      data-vv-as="kwota"
    />
    <div class="row">
      <input-type
        v-model="value['invoiceNumber']"
        v-validate="`${value['date'] ? 'required' : ''}`"
        class="col-xs-6"
        :error-message="vErrors.first('invoiceNumber')"
        label="Numer faktury"
        name="invoiceNumber"
        data-vv-value-path="value"
        data-vv-as="numer faktury"
      />
      <date-picker-type
        v-model="value['date']"
        v-validate="`${value['invoiceNumber'] ? 'required' : ''}`"
        class="col-xs-6 fv-datepicker"
        :error-message="vErrors.first('date')"
        label="Data"
        name="date"
        data-vv-value-path="value"
        data-vv-as="data"
      />
    </div>
    <text-area-type
      v-model="value['description']"
      v-validate="{rules: {max: 512}}"
      label="Opis"
      name="description"
      placeholder="Wpisz treść... Opis nie może przekroczyć 512 znaków."
      :error-message="vErrors.first('description')"
      :required="false"
      data-vv-value-path="value"
      data-vv-as="opis"
    />
    <label for="delay-reason">Powód opóźnienia</label>
    <vue-multiselect
      v-if="value.type === `Koszt`"
      id="delay-reason"
      ref="delay"
      v-model="value['delay']"
      :multiple="true"
      :clear-on-select="false"
      label="label"
      track-by="value"
      :disabled="!value"
      :options="delayTypes"
      :empty-first="true"
      placeholder="Zaznacz powód opóźnienia"
      select-label="Zaznacz"
      selected-label="Zaznaczony"
      deselect-label="Odznacz"
      data-vv-as="delay"
    />
    <div
      v-if="taskType === 'main'"
      class="row"
    >
      <div class="col-xs-2 correction-checkbox-first-edit-container">
        <label for="correction-checkbox-edit" class="correction-checkbox-first-label-edit">Korekta</label>
        <input
          id="correction-checkbox-edit"
          v-model="value['correction']"
          type="checkbox"
          @change="(event) => handleCorrectionChange(event)"
        >
        <label for="correction-checkbox-edit"></label>
      </div>

      <input-type
        v-if="value['correction']"
        v-model="value['originalInvoiceNumber']"
        v-validate="`${value['correction'] ? 'required' : ''}`"
        class="col-xs-10"
        :error-message="vErrors.first('correctionInvoiceNumber')"
        label="Oryginalny numer faktury"
        name="correctionInvoiceNumber"
        data-vv-value-path="value"
        data-vv-as="oryginalny numer faktury"
      />
    </div>
  </form>
</template>

<script>
import FormModalValidation from '@/components/share/form/FormModalValidation'
import MoneyType from '@/components/share/form/type/MoneyType'
import TextAreaType from '@/components/share/form/type/TextAreaType'
import {Validator} from 'vee-validate'
import InputType from '@/components/share/form/type/InputType'
import DatePickerType from '@/components/share/form/type/DatePickerType'
import VueMultiselect from 'vue-multiselect/src/Multiselect'

export default {
  components: {
    VueMultiselect,
    DatePickerType,
    InputType,
    MoneyType,
    TextAreaType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {type: Object, required: true},
    taskType: {type: String, required: true}

  },
  data () {
    return {
      delayTypes: [
        {value: 1, label: 'Nieterminowy kontakt'},
        {value: 2, label: 'Nieterminowe załączenie dokumentacji'},
        {value: 3, label: 'Niekompletna dokumentacja'},
        {value: 4, label: 'Brak uwag'}
      ]
    }
  },
  created () {
    Validator.extend('money', {
      getMessage: field => 'Pole ' + field + ' musi być większe od 0 zł.',
      validate: (value) => {
        if (parseFloat(value.toString().replace(',', '.')) <= 0) {
          return false
        }
        return true
      }
    })
  },
  methods: {
    handleCorrectionChange (event) {
      if (event.target.checked === false) {
        this.$emit('invoiceClearCorrection')
      }
    }
  }
}
</script>
<style>
#invoiceForm .fv-datepicker > div > .btn-group.dropdown {
  display: block;
}

.correction-checkbox-first-label-edit {
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
}

.correction-checkbox-first-edit-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
