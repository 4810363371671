import api from '@/api'
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'

export default {
  mixins: [ErrorNotify],
  methods: {
    async getCustomerData (taskGroup) {
      try {
        let response = api.request(this.service, 'get', `/summary/${taskGroup}/${this.taskId}/customer`)
        let {data} = await response
        this.customerData = data
      } catch (e) {
        this.errorNotify(e)
      }
    },
    async getCreditPurposeData (taskGroup) {
      try {
        let response = api.request(this.service, 'get', `/summary/${taskGroup}/${this.taskId}/credit-purpose`)
        let {data} = await response
        this.creditPurposeData = data
      } catch (e) {
        this.errorNotify(e)
      }
    },
    async getRealEstateData (taskGroup) {
      try {
        let response = api.request(this.service, 'get', `/summary/${taskGroup}/${this.taskId}/real-estates`)
        let {data} = await response
        this.realEstateData = data
      } catch (e) {
        this.errorNotify(e)
      }
    },
    async getMainInvoicesData () {
      try {
        let response = api.request(this.service, 'get', `/summary/main/${this.taskId}/invoices-main`)
        let {data} = await response
        this.mainInvoicesData = data
      } catch (e) {
        this.errorNotify(e)
      }
    },
    async getAppraisalsInvoicesData () {
      try {
        let response = api.request(this.service, 'get', `/summary/main/${this.taskId}/invoices-appraisal`)
        let {data} = await response
        this.appraisalsInvoicesData = data
      } catch (e) {
        this.errorNotify(e)
      }
    },
    async getAppraisalInvoicesData () {
      try {
        let response = api.request(this.service, 'get', `/summary/appraisal/${this.taskId}/invoices`)
        let {data} = await response
        this.appraisalInvoicesData = data
      } catch (e) {
        this.errorNotify(e)
      }
    }
  }
}
